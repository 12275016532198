<template>
  <v-app>
    <v-navigation-drawer v-model="controls.drawer" width="322px" floating app>
      <v-layout
        align-center
        justify-center
        column
        class="app-logo-container"
        style="height: 322px"
      >
        <div class="app-logo-sheet">
          <v-img
            :src="require('@/assets/images/logo.png')"
            width="100px"
            height="100px"
            class="ma-6"
          ></v-img>
        </div>
        <div class="app-company--name mt-8" style="text-align: center">
          {{ pageTitle }}
        </div>
        <div class="app-project--name mt-2">Admin CMS</div>
      </v-layout>

      <v-list class="app-nav" flat>
        <v-subheader class="mb-5">
          <div class="app-nav--title mx-auto">NAVIGATION</div>
        </v-subheader>
        <div v-for="item in navItems" :key="item.label">
          <v-list-item
            v-if="!item.items"
            link
            active-class="white black--text"
            :to="item.to"
          >
            <v-list-item-content>
              <v-list-item-title class="pa-2 app-nav--item">{{
                item.label
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  class="pa-2 app-nav--item"
                  v-text="item.label"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              link
              v-for="child in item.items"
              :key="child.title"
              :to="child.to"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="pa-2 app-nav--item"
                  v-text="child.label"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-menu top offset-y>
            <template v-slot:activator="{ on }">
              <v-list-item two-line v-on="on">
                <v-list-item-avatar>
                  <user-avatar size="40" :avatar="admin.avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ admin.full_name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ admin.email ? admin.email : admin.phone_number }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'admin.details', params: { id: admin.id } }"
              >
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logoutConfirmDialog = true">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main class="main-content">
      <div class="px-xl-12 px-lg-10 px-md-6 px-sm-4 px-xs-2">
        <slot />
      </div>
    </v-main>
    <ConfirmModal
      v-model="logoutConfirmDialog"
      title="Logout"
      message="Are you sure you want to logout?"
    >
      <v-btn text @click="logoutConfirmDialog = false">Cancel</v-btn>
      <v-btn color="primary" text @click="logout">Logout</v-btn>
    </ConfirmModal>
  </v-app>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mapState, mapActions, mapGetters } from 'vuex'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'MainLayout',

  components: {
    UserAvatar,
    ConfirmModal,
  },

  mixins: [ControlsMixin],

  data() {
    return {
      pageTitle: process.env.VUE_APP_TITLE,
      navItems: [
        { label: 'Users', to: { name: 'users' } },
        {
          label: 'Courses',
          items: [
            {
              label: 'Courses',
              to: { name: 'courses' },
            },
            {
              label: 'Courses Categories',
              to: { name: 'course-categories' },
            },
          ],
        },
        {
          label: 'Exercises',
          items: [
            {
              label: 'Exercises',
              to: { name: 'exercises' },
            },
            {
              label: 'Exercise Items',
              to: { name: 'exercises-items' },
            },
            {
              label: 'Exercise Categories',
              to: { name: 'categories' },
            },
          ],
        },
        { label: 'Videos', to: { name: 'videos' } },
        { label: 'Banner and Social Media', to: { name: 'banners' } },
        { label: 'Campaign Notifications', to: { name: 'campaigns' } },
        { label: 'Settings', to: { name: 'settings' } },
      ],
      logoutConfirmDialog: false,
    }
  },

  computed: {
    ...mapState({
      admin: (state) => state.auth.user,
    }),
    ...mapGetters({
      avatarThumb: 'auth/avatarThumb',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
  },
}
</script>
